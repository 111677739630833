<template>
  <div>
    <Header />
    <section class="bg-black main_section">
      <div class="gap-5"></div>
      <div class="gap-5 hideMobile"></div>
      <div class="container text-center mt-5">
        <img class="big_img" src="/assets/img/text_img.png" alt="" />
        <h1 class="text-center">REINVENTING FICTION THROUGH AI.</h1>
      </div>
    </section>

    <div
      class="text-center"
      style="margin-top: -30px; padding-top: 40px; background: #000"
    >
      <img src="/assets/img/home_top.png" width="100%" />
    </div>
    <!-- PROJECT -->
    <section
      id="project"
      class="project bg-black"
      style="margin-top: 0px; padding-top: 50px; padding-bottom: 50px"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="container p-5">
              <img
                class="big_img"
                src="/assets/img/pattern_project.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div
              class="
                container
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
              style="height: 100%"
            >
              <h1>THE PROJECT</h1>
              <div class="mt-5">
                <p>
                  THIS PROJECT IS ABOUT STORIES, ARTIFICIAL INTELLIGENCE AND
                  NFTS. IT’S MADE BY IMPSSBL, A CREATIVE WEB3 STUDIO FOUNDED BY
                  AN EX GOOGLE.
                </p>

                <p class="mt-5">
                  We paired human-drawn CHARACTERS with stories written in
                  artificial intelligence.
                </p>

                <p class="mt-5">
                  EACH NFT GIVES EARLY ACCESS TO THE FOLLOWING IMPSSBL PROJECTS,
                  AND TO OUR CUSTOM AI ENGINE, WHICH YOU CAN USE FOR YOUR OWN
                  MOVIES, STORIES, SCRIPTS.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- STORIES -->
    <section id="stories" class="bg-black">
      <div class="banner_title">
        <h2>THE AI-WRITTEN STORIES</h2>
      </div>
      <div class="container">
        <div class="gap-5 hideMobile"></div>
        <p class="m-5">
          They could be complete plots, dialogues, scenes, character memories,
          titles, locations and times. Be part of a rare collapsed saga or
          orphans. But they have one thing in common: they are unique to your
          character. Uh, and a machine dreamed of them.
        </p>
      </div>
      <div>
        <img class="full_width" src="/assets/img/mkg_1.jpeg" alt="" />
      </div>
    </section>

    <!-- CHARACTER -->
    <section id="character" class="bg-black">
      <div class="banner_title">
        <h2>THE HUMAN-DRAWN CHARACTERS</h2>
      </div>
      <div class="container">
        <div class="gap-5 hideMobile"></div>
        <p class="m-5">
          The project is divided in SAGAS. The first SAGA tells the story of
          2,500 characters. Each can have over 1,200 traits and a narrative
          role: heroes, gangsters, time-travelers... Each SAGA will have its own
          associated NFT novel, written partly by the community (if you
          contribute to the plot, you become a co-author) and partly by AI.
        </p>
      </div>
      <div>
        <img class="full_width" src="/assets/img/mkg_2.jpeg" alt="" />
        <img class="full_width" src="/assets/img/mkg_3.jpeg" alt="" />
      </div>
    </section>

    <!-- ROADMAP -->
    <section id="roadmap" class="bg-black">
      <div class="banner_title">
        <h2>THE ROADMAP</h2>
      </div>
      <div class="container">
        <div class="gap-5 hideMobile"></div>
        <p class="m-5">
          The PoS roadmap is connected to other Impssbl projects, such as
          Superclothes (NFT luxury clothes with made-in-Italy physical versions)
          and Impssbl Frames, that supplies high-end digital frames for your
          NFTs. In addition, NFT holders will have an AI Story Engine pass for
          personal usage (100 stories for OG characters holders and 25 for
          regular character holders) and whitelisting access to 3 Impssbl
          projects for OG character holders (1 for regular character holders).
          Lastly, every week we will host chapter battles to decide what happens
          in the PoS master story. The best storytellers become co-authors of
          the PoS novel written by the PoS team at the end of the project.
        </p>
      </div>
      <div>
        <img class="full_width" src="/assets/img/mkg_4.png" alt="" />
      </div>
    </section>

    <!-- STORY -->
    <section id="story" class="bg-black">
      <div class="banner_title">
        <h2>THE STORY</h2>
      </div>
      <div>
        <img class="full_width" src="/assets/img/mkg_5.png" alt="" />
      </div>
    </section>

    <!-- TEAM -->
    <section id="team" class="bg-black">
      <div>
        <img class="full_width" src="/assets/img/mkg_6.png" alt="" />
      </div>
    </section>

    <!-- LAUNCH -->
    <section id="team" class="bg-black">
      <div class="gap-5 bg-black"></div>
      <div class="gap-5 hideMobile"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-11">
            <div class="container p-5 mt-5 mb-4">
              <div class="row justify-content-center">
                <div class="col-12 col-md-12 col-lg-12">
                  <h1 class="yellow text-center me-5" style="font-size: 5rem">
                    THE OFFICIAL LAUNCH<br />
                  </h1>
                </div>
              </div>
            </div>
            <div class="container text-start">
              <p class="text-center" style="font-size: 1.8rem">
                The Opening is our first public launch. It’s a box of 399
                characters, each with a unique AI story and 18 possible roles
                (out of the 99 that will be revealed in further boxes).
              </p>
            </div>
            <div class="mt-5 mb-5"></div><br /><br />
            <p class="text-left" style="font-size: 1.8rem; text-align:left;">
              <b>CHAIN:</b>&nbsp; Polygon<br /><br />
              <b>TIMELINE:</b>&nbsp;7 January 2021<br /><br />
              <b>PRICE:</b>&nbsp;0.08 ETH for 1 character + 1 story (contained in the
              character’s metadata)<br /><br />
              <b>VERIFIED SMART CONTRACT:</b>&nbsp;
              <a
                href="https://polygonscan.com/address/0xe0a52be1c766ffee0bd72296c78d86fa7f48aa07"
                target="_blank"
                >0xe0a52bE1c766Ffee0Bd72296c78D86fA7f48Aa07</a
              ><br /><br />
              <b>MINTING:</b>&nbsp;The characters & stories are pre-minted by the PoS team
            </p>
            <!-- BUTTONS --><br /><br /><br /><br />
            <div
              class="
                container
                d-flex
                flex-column flex-md-row flex-lg-row
                justify-content-center
                mb-5
              "
            >
              <a class="mx-5" href="/choose-a-role">
                <div class="btn-yellow" style="padding: 20px 60px">
                  BUY A ROLE
                </div>
              </a>
            </div>
            <div class="gap-5"></div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<style scoped>
#videoBG {
  position: absolute;
  top: 0;
  left: 0;
  /*not work if the screen ratio is below 16/9*/
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
}
</style>
<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
    gtag_report_conversion(url) {
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };
      window.gtag("event", "conversion", {
        send_to: "AW-10795993000/3WlGCP7Ij_4CEKiP95so",
        event_callback: callback,
      });
      setTimeout(function () {
        window.location = url;
      }, 1000);
      return false;
    },
  },
};
</script>
