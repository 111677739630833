<template>
  <div class="footer">
    <div
      class="container-footer d-flex justify-content-around align-items-center"
    >
      <div class="footerText">SIGN UP HERE</div>
      <div id="mc_embed_signup">
        <form
          action="https://proofofstory.us5.list-manage.com/subscribe/post?u=0910fce3a51510bfc3bcc8ddb&amp;id=4bd091b827"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <div id="mc_embed_signup_scroll">
            <div class="mc-field-group">
              <input
                type="email"
                value=""
                name="EMAIL"
                class="required email"
                id="mce-EMAIL"
                placeholder="name@gmail.com"
              />
            </div>
            <div class="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button"
              />
            </div>
          </div>
          <div id="mce-responses" class="clear">
            <div
              class="response"
              id="mce-error-response"
              style="display:none"
            ></div>
            <div
              class="response"
              id="mce-success-response"
              style="display:none"
            ></div>
          </div>
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true">
            <input
              type="text"
              name="b_0910fce3a51510bfc3bcc8ddb_4bd091b827"
              tabindex="-1"
              value=""
            />
          </div>
        </form>
      </div>
      <a href="#" style="text-decoration: none; display:none">
        <div class="footerText">SIGN UP HERE</div></a
      >
      <a href="/PoS_Whitepaper_Jan22.pdf" target="_blank" style="text-decoration: none;">
        <div class="footerText">WHITE PAPER</div></a
      >
      <a href="/terms-and-conditions" style="text-decoration: none;">
        <div class="footerText">PRIVACY POLICY</div></a
      >
      <a href="/assets/term_conditions.pdf" style="text-decoration: none;">
        <div class="footerText">TERMS & CONDITIONS</div></a
      >
      <a href="" style="text-decoration: none;">
        <div class="footerText">
          <em>Copyright ©{{ new Date().getFullYear() }} Impssbl Corp.</em>
        </div></a
      >
      <div class="d-flex socialMenu2">
        <!-- <a href="https://www.instagram.com/proofofstory/" target="_blank"
          ><i class="fab fa-instagram"></i
        ></a> -->
        <a href="https://twitter.com/ProofStory" target="_blank"
          ><i class="fab fa-twitter"></i
        ></a>
        <!-- <a href="https://t.me/proofofstory" target="_blank"
          ><i class="fab fa-telegram-plane"></i
        ></a> -->
        <a href="https://discord.com/invite/R9Pm34uY9g" target="_blank"
          ><i class="fab fa-discord"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {};
  },
};
</script>
