<template>
  <div class="header">
    <!-- NAV -->
    <a href="/"><div class="brand-name hideMobile">PROOF OF STORY</div></a>
    <div class="topnav" id="myTopnav">
      <a class="hideDesktop" href="/">PROOF OF STORY</a>
      <a href="/#project">PROJECT</a>
      <a href="/#story">STORIES</a>
      <a href="/#character">CHARACTERS</a>
      <a href="/#roadmap">ROADMAP</a>
      <a href="/#story">STORY</a>
      <a href="/#team">TEAM</a>
      <a href="/sale" class="text-start yellow" style="font-weight: bold;">CLAIM A CHARACTER</a>
      <div class="socialMenu d-flex flex-column">
        <a href="https://twitter.com/ProofStory" target="_blank"
          ><i class="fab fa-twitter"></i
        ></a>
        <a href="https://discord.com/invite/R9Pm34uY9g"
          ><i class="fab fa-discord"></i
        ></a>
      </div>
      <a href="javascript:void(0);" class="icon" @click="myFunction()">
        <i id="toggle-menu" class="fa fa-bars"></i>
      </a>
    </div>
    <!-- END -->
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {};
  },
  methods: {
    myFunction: function() {
      var x = document.getElementById("myTopnav");
      var icon = document.getElementById("toggle-menu");
      icon.classList.toggle("fa-times");
      if (x.className === "topnav") {
        x.className += " responsive fade-in";
      } else {
        x.className = "topnav";
      }
    },
    gtag_report_conversion(url) {
      var callback = function() {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };
      window.gtag("event", "conversion", {
        send_to: "AW-10795993000/3WlGCP7Ij_4CEKiP95so",
        event_callback: callback,
      });
      setTimeout(function() {
        window.location = url;
      }, 1000);
      return false;
    },
  },
};
</script>
